/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum EnvEnum {
	dev = 'Development',
	prod = 'Production'
}

export const env: EnvEnum = process.env.NODE_ENV == 'development' ? EnvEnum.dev : EnvEnum.prod;

const AppSettings = {
	auth: {
		access: {
			name: 'accessToken',
			durationDays: 24 / 1
		},
		refresh: {
			name: 'refreshToken',
			durationDays: 30
		}
	},
	timeZoneName: 'Europe/Moscow',
	api: {
		// url: 'http://localhost:3010',
		url: 'https://brain.evolution.life/',
		paymentUrl: 'https://edu.evolution.life/checkout?orderId=',
	},
}

export { AppSettings }
