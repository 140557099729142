/* eslint-disable @typescript-eslint/no-unused-vars */
import { useApi } from '../../hooks/useApi';
import styles from './workDay.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { Button } from '../../components/UI/Button/Button';
import {ReactComponent as PhoneIco} from './icon/phone.svg';
import {ReactComponent as UserIco} from './icon/user.svg';
import {ReactComponent as WhatsAppIco} from './icon/whatsapp.svg';
import {ReactComponent as TelegramIco} from './icon/telegram.svg';
import CurrentClient from '../../api/workDay/dto/GetCurrentClientResp';
import { useEffect, useState } from 'react';
import UnrealizedPopUP from '../../components/popUp/Unrealized/Unrealized';
import CompletePopUP from '../../components/popUp/Complete/Complete';
import CreateOrder from '../../components/popUp/CreateOrder/CreateOrder';
import { AppSettings } from '../../appSettings';
import CompleteTaskPopUP from '../../components/popUp/CompleteTask/CompleteTask';
// import moment from 'moment';
import moment from 'moment-timezone';
import RealizedPopUP from '../../components/popUp/Realized/Realized';
import DeleteOrderPopUP from '../../components/popUp/DeleteOrder/DeleteOrder';
import TaskListPopUP from '../../components/popUp/TaskList/TaskList';
import CallToPopUP from '../../components/popUp/CallTo/CallTo';
import { formatMySQLDatetime } from '../../utils/date';
import { formatNumberWithSpaces } from '../../utils/numbers';
import ManagerStat from '../../api/analytics/dto/ManagerStat';
require('moment/locale/ru');


const WorkDay: React.FC = observer(() => {
	const api = useApi();
	const store = useStores();

	const [currentClient, setCurrentClient] = useState<CurrentClient | null>();
	const [stats, setStats] = useState<ManagerStat>();

	const getCurrentClient = async (taskId: string | null = null) => {

		try {
			store.notificationsStore.showPreloader();
			setCurrentClient(null);
			const {body} = await api.workDay.getCurrentClient(store.userStore.user?.axl_id || '', taskId);
	
			if (body && body?.deal) {
				const {body: ordersDeal} = await api.workDay.getOrdersForDeal(body?.deal.id || '');

				if (ordersDeal) {
					setCurrentClient({...body, orders: ordersDeal});
				} else {
					setCurrentClient(body);
				}
			} else {
				setCurrentClient(body);
			}

		} catch (e) {
			store.notificationsStore.setError('Ошибка загрузки типов сделок');
		} finally {
			store.notificationsStore.hidePreloader();
		}

	};

	const getStats = async () => {
		if (store.userStore.user?.axl_id) {

			try {
				store.notificationsStore.showPreloader();

				const currentWeekStart = moment.tz(AppSettings.timeZoneName).startOf('week').toDate();
				const currentWeekEnd = moment.tz(AppSettings.timeZoneName).endOf('week').toDate();
			
				const {body} = await api.analytics.getManagerStats(
					formatMySQLDatetime(currentWeekStart), 
					formatMySQLDatetime(currentWeekEnd, true), 
					store.userStore.user?.axl_id || ''
				);
	
				if (body) {
					setStats(body);
				}

			} catch (e) {
				store.notificationsStore.setError('Ошибка загрузки статистики');
			} finally {
				store.notificationsStore.hidePreloader();
			}

		}

	};

	useEffect(() => {
		if (store.userStore.user?.axl_id) {
			getCurrentClient();
			getStats();
		}
	}, [store.userStore.user?.axl_id]);

	const clickProfile = () => {
		window.open('https://admin.accelonline.io/crm/contacts/' + currentClient?.client?.id, '_blank');
	}


	const whatsAppProfile = () => {
		window.open('https://wa.me/' + currentClient?.client?.phone, '_blank');
	}

	const telegramProfile = () => {
		window.open('https://t.me/' + currentClient?.client?.phone, '_blank');
	}

	const openDeal = () => {
		window.open('https://admin.accelonline.io/pipelines/'+ currentClient?.pipeline?.id + '/deal/' + currentClient?.deal?.id, '_blank');
	}

	function formatDate(dateString: string) {
		// Укажите формат строки даты, чтобы moment.js мог её распознать
		const date = moment(dateString, 'YYYY-MM-DD HH:mm:ss.SSS Z [UTC]');

		if (!date.isValid()) {
			return 'Invalid date';
		}

		return date.format('D MMMM HH:mm');
	}

	function isDateExpired(dateString: string): boolean {
		// Преобразуем строку даты в формат ISO 8601
		const parsedDate = dateString.replace(' +0000 UTC', 'Z').replace(' ', 'T');

		// Создаем объект moment из строки
		const date = moment(parsedDate);

		// Проверяем, корректна ли дата
		if (!date.isValid()) {
			return false; // Если дата некорректна, вернем false
		}

		// Сравниваем дату с текущим временем
		return moment().isBefore(date);
	}

	const [showPopupRealized, setShowPopupRealized] = useState(false);

	const togglePopupRealized = () => {
		setShowPopupRealized(!showPopupRealized);
	};

	const [showPopupUnrealized, setShowPopupUnrealized] = useState(false);

	const togglePopupUnrealized = () => {
		setShowPopupUnrealized(!showPopupUnrealized);
	};

	const [showPopupComplete, setShowPopupComplete] = useState(false);

	const togglePopupComplete = () => {
		setShowPopupComplete(!showPopupComplete);
	};

	const [showPopupCreateOrder, setShowPopupCreateOrder] = useState(false);

	const togglePopupCreateOrder = () => {
		setShowPopupCreateOrder(!showPopupCreateOrder);
	};

	const [showPopupCompleteTask, setShowPopupCompleteTask] = useState(false);

	const togglePopupCompleteTask = () => {
		setShowPopupCompleteTask(!showPopupCompleteTask);
	};

	const [showPopupDeleteOrder, setShowPopupDeleteOrder] = useState(false);
	const [deleteOrderId, setDeleteOrderId] = useState('');

	const togglePopupDeleteOrder = () => {
		setShowPopupDeleteOrder(!showPopupDeleteOrder);
	};

	const resetDeleteOrderId = () => {
		setDeleteOrderId('');
	}

	const setDeleteOrder = (orderId: string) => {
		setDeleteOrderId(orderId);
		setShowPopupDeleteOrder(true);
	}

	const [showPopupTasksList, setShowPopupTasksList] = useState(false);

	const togglePopupTasksList = () => {
		setShowPopupTasksList(!showPopupTasksList);
	};

	const [showPopupCallTo, setShowPopupCallTo] = useState(false);

	const togglePopupCallTo = () => {
		setShowPopupCallTo(!showPopupCallTo);
	};


	const copyOrderPayUrl = (orderId: string) => {
		const url = AppSettings.api.paymentUrl + orderId;
		navigator.clipboard.writeText(url);
		store.notificationsStore.setNotifications('Ссылка на заказ скопирована');
	}

	const setClient = (id: string) => {
		getCurrentClient(id);
	}

	return (
		<>
			{currentClient && showPopupUnrealized && <UnrealizedPopUP client={currentClient} onClose={togglePopupUnrealized} updateClient={getCurrentClient}/>}
			{currentClient && showPopupRealized && <RealizedPopUP client={currentClient} onClose={togglePopupRealized} updateClient={getCurrentClient}/>}
			{currentClient && showPopupComplete && <CompletePopUP client={currentClient} onClose={togglePopupComplete} updateClient={getCurrentClient}/>}
			{currentClient && showPopupCreateOrder && <CreateOrder client={currentClient} onClose={togglePopupCreateOrder} updateClient={getCurrentClient}/>}
			{currentClient && showPopupCompleteTask && <CompleteTaskPopUP client={currentClient} onClose={togglePopupCompleteTask} updateClient={getCurrentClient}/>}
			{deleteOrderId != '' && showPopupDeleteOrder && <DeleteOrderPopUP resetDeleteOrderId={resetDeleteOrderId} orderId={deleteOrderId} onClose={togglePopupDeleteOrder} updateClient={getCurrentClient}/>}
			{currentClient && showPopupCallTo && <CallToPopUP  phone={currentClient.client.phone} onClose={togglePopupCallTo}/>}
			{showPopupTasksList && <TaskListPopUP onClose={togglePopupTasksList} setClient={setClient}/>}
			<div className={styles.container}>
				<div className={styles.pageName}>

					<div className={styles.top}>

						<div className={styles.topRight}>
							<div className={styles.title}>
								<h2>Рабочий день</h2>
								<p>Привет 👋 {store.userStore.user?.name?.split(' ')[0]}! Давай поработаем!</p>
							</div>
							<Button style={{width: 100, margin: 5}} appearance='link' onClick={() => setShowPopupTasksList(true)}>Задачи</Button>
						</div>

						<div className={styles.topLeft}>

							<div className={styles.topLeftSales}>
								<span><b>Выручка:</b> {formatNumberWithSpaces(stats?.revenue ? stats?.revenue.toFixed(2) : 0)} $</span>
								<span><b>Продаж:</b> {stats?.sales}</span>
								<span><b>Ср.чек:</b> {formatNumberWithSpaces(stats?.avg_check ? stats?.avg_check.toFixed(2) : 0)} $</span>
							</div>

							<div>
								<span><b>Звонков:</b> {stats?.calls}</span>
								<span><b>Интервью:</b> {stats?.interviews}</span>
							</div>

						</div>

					</div>

					<div className={styles.horizontalLine}/>

				</div>

				{currentClient && currentClient !== null &&

				<>

					<div>

						<div className={styles.clientInfoContainer}>
							<h2 className={styles.clientInfoTitleContainer}>Взять в работу клиента,<br/>{currentClient.client.name}</h2>

							<div className={styles.clientInfoIconsContainer}>

								<div className={styles.userIcon} onClick={clickProfile}>
									<UserIco width={20} fill='white'/>
								</div>

								<div className={styles.userIcon} onClick={whatsAppProfile}>
									<WhatsAppIco width={20} fill='white'/>
								</div>

								<div className={styles.userIcon} onClick={telegramProfile}>
									<TelegramIco width={20} fill='white'/>
								</div>

								<div className={styles.phoneIcon} onClick={() => setShowPopupCallTo(true)}>
									<PhoneIco width={20} fill='white'/>
								</div>

							</div>

						</div>

						{currentClient.deal &&

						<div className={styles.buttonsActions}>
							<Button style={{width: 200, margin: 5}} appearance='link' onClick={() => setShowPopupUnrealized(true)}>Не реализовано</Button>
							<Button style={{width: 200, margin: 5}} appearance='link' onClick={() => setShowPopupRealized(true)}>Реализовано</Button>
							<Button style={{width: 200, margin: 5}} appearance='auth' onClick={() => setShowPopupComplete(true)}>Выполнено</Button>
						</div>

						}

						{!currentClient.deal &&

						<div style={{display: 'flex'}}>
							<Button style={{width: 350, margin: 5}} appearance='link' onClick={() => setShowPopupCompleteTask(true)}>Закрыть задачу</Button>
						</div>

						}

					</div>

					<div className={styles.horizontalLine} style={{marginTop: 30}}/>

					<div className={styles.taskContentTitle}>
						<h3>Задача, сделка и заказы</h3>
						{currentClient.deal &&
							<Button style={{width: 150, margin: 5}} appearance='link' onClick={() => setShowPopupCreateOrder(true)}>Создать заказ</Button>
						}
					</div>

					<div className={styles.taskContentItems}>

						<div style={{height: 'max-content', padding: 20, margin: 5, background: 'white', maxWidth: 340, borderRadius: 10, width: 'max-content', boxShadow: '0 2px 12px rgb(167 177 210 / 30%)'}}>
							<h4 style={{color: isDateExpired(currentClient.task.date_start) ? '#272443' : 'red' }}>Задача на {formatDate(currentClient.task.date_start)}</h4>
							<p>{currentClient.task.title}</p>
						</div>

						{currentClient.deal && currentClient?.pipeline &&

						<div style={{height: 'max-content', padding: 20, margin: 5, background: 'white', maxWidth: 340, borderRadius: 10, width: 'max-content', boxShadow: '0 2px 12px rgb(167 177 210 / 30%)'}}>
							<h4><a onClick={openDeal} style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}}>Сделка</a> в воронке {currentClient?.pipeline?.name}</h4>
							<div style={{display: 'flex', justifyItems: 'center', alignItems: 'center', marginTop: 10, marginBottom: 5}}>
								<div style={{width: 10, height: 10, background: currentClient.deal.stage_color, marginRight: 5, borderRadius: 100}}/>
								<p style={{fontSize: 14}}>{currentClient.deal.stage_name}</p>
							</div>

							<div style={{display: 'flex', flexWrap: 'wrap'}}>
								{currentClient.deal.tags && currentClient.deal.tags.map((tag) => (
									<div key={tag.id} style={{background: tag.color, marginTop: 10, margin: 2, padding: 5, borderRadius: 4, width: 'max-content'}}>
										<p style={{fontSize: 10, color: 'white'}}>{tag.name}</p>
									</div>
								))}
							</div>

						</div>
						
						}

						{currentClient.orders && currentClient.orders.map((order) => (
							<div key={order.id} style={{height: 'max-content', padding: 20, margin: 5, background: 'white', maxWidth: 340, borderRadius: 10, width: 'max-content', boxShadow: '0 2px 12px rgb(167 177 210 / 30%)'}}>
								<h4><a href={'https://admin.accelonline.io/orders/' + order.id} target='_blank' style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}}>Заказ</a> на {order.products[0].internal_name !== '' ? order.products[0].internal_name : order.products[0].name}</h4>
								<p style={{marginTop: 10}}>Оплачено: {order.paid_amount} $ из {order.total_amount} $</p>
								<Button style={{width: '100%', marginTop: 10}} appearance='link' onClick={() => copyOrderPayUrl(order.id)}>Скопировать URL</Button>
								<Button style={{width: '100%', marginTop: 10}} appearance='link' onClick={() => setDeleteOrder(order.id)}>Удалить</Button>
							</div>			

						))}
					</div>

				</>
				}
				

			</div>


		</>
	);
});

export default WorkDay;